@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap');
@import url('../node_modules/ckeditor-tailwind-reset/ckeditor-tailwind-reset.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cream__bg {
  background: #FFEFCB;
}

.red_btn {
  color: #FF3D00;
}

.dot {
  background: #E02201;
}

.primary__dark {
  color: #011433;
}

.dark__btn {
  background: #001427;
}

.audio__player {
  background: #001427;
  border: 1px solid #001427;
  box-shadow: 4px 4px 0px #FF3D00;
  border-radius: 16px;
  box-sizing: border-box;
  color: #FFFFFF;
}

.primary__btn {
  background: #BF0603;
  border: 2px solid #BF0603;
  box-shadow: 2px 4px 0px #FF3D00;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.google__btn {
  background: #FFFFFF;
  border: 2px solid #001427;
  box-shadow: 2px 4px 0px #001427;
  border-radius: 16px;
}

.black__shadow {
  border: 1px solid #001427;
  box-shadow: 4px 8px 0px #001427;
}

.text-gradient {
  background: linear-gradient(92.58deg, #FF3D00 19.76%, #BF0603 98.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.selected-nav,
.unselected-nav:hover,
.re__btn:hover,
.audio__player:hover {
  background: #FF3D00;
  box-shadow: 2px 4px 0px #001427;
  border-radius: 16px;
  transition: ease .4s;
}

.selected-nav:hover {
  box-shadow: 4px 8px 0px #001427;
  transition: ease .4s;
}

.search {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #455564;
}

.recent__activity {
  background: #FFFFFF;
  border: 1px solid #001427;
  box-shadow: 2px 4px 0px #001427;
  border-radius: 16px;
}

.dot__active {
  background: #011433;
}

.dot__unactive {
  background: #BEBFFA;
}

.cream {
  color: #FFEFCB;
}

.gray__text {
  color: #455564;
}

.purple--tag {
  background: #EECCFF;
  border: 2px solid #001427;
  border-radius: 16px;
}

.light--brown {
  background: #FFEFCB;
}

.dark__text {
  color: #001427;
}

.re__btn {
  background: #FF3D00;
  box-shadow: 2px 2px 0px #001427;
  border-radius: 16px;
  transition: ease .3s;
}

.live--red {
  background: #FF0C09;
}

.orange {
  background: #FF3D00;
}

.stick {
  width: 1px;
}

.orange--text {
  color: #FF3D00;
}

.orange--border:hover {
  border-color: #FF3D00;
}

.orange--bg {
  background: #FF3D00;
}

.text-choice {
  background: #FFFFFF;
  box-shadow: 2px 4px 4px rgba(0, 20, 39, 0.08);
}

.gray__bg {
  background: #ADB2BC;
}

.light--gray {
  background: #FAFAFA;
}