table th:first-child {
    border-top-left-radius: 16px;
}

table th:last-child {
    border-top-right-radius: 16px;
}

table tr:first-child {
    border-bottom-left-radius: 16px;
}

table tr:last-child {
    border-bottom-right-radius: 16px;
}

.rhap_time,
.rhap_volume-bar {
    color: #FFFFFF;
}

.rhap_progress-indicator,
.rhap_volume-indicator {
    background: #FFFFFF;
}

.dAZYal {
    border-radius: 16px;
}